exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-the-monsters-tsx": () => import("./../../../src/pages/about/the-monsters.tsx" /* webpackChunkName: "component---src-pages-about-the-monsters-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-appendix-tsx": () => import("./../../../src/pages/appendix.tsx" /* webpackChunkName: "component---src-pages-appendix-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-table-jsx": () => import("./../../../src/pages/table.jsx" /* webpackChunkName: "component---src-pages-table-jsx" */),
  "component---src-templates-book-tsx": () => import("./../../../src/templates/book.tsx" /* webpackChunkName: "component---src-templates-book-tsx" */),
  "component---src-templates-bookmonster-tsx": () => import("./../../../src/templates/bookmonster.tsx" /* webpackChunkName: "component---src-templates-bookmonster-tsx" */),
  "component---src-templates-monster-tsx": () => import("./../../../src/templates/monster.tsx" /* webpackChunkName: "component---src-templates-monster-tsx" */),
  "component---src-templates-printmonster-tsx": () => import("./../../../src/templates/printmonster.tsx" /* webpackChunkName: "component---src-templates-printmonster-tsx" */),
  "component---src-templates-setting-tsx": () => import("./../../../src/templates/setting.tsx" /* webpackChunkName: "component---src-templates-setting-tsx" */)
}

